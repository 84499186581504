// used to mark unsupported tokens, these are hosted lists of unsupported tokens

export const UNSUPPORTED_LIST_URLS: string[] = []
// export const OPTIMISM_LIST = 'https://static.optimism.io/optimism.tokenlist.json'

export const XDC_MAINLIST_PREFIX = 'https://raw.githubusercontent.com/XSwapProtocol/xdc-token-list/master'
export const XDC_MAINLIST = `${XDC_MAINLIST_PREFIX}/mainnet.tokenlist.json`

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  XDC_MAINLIST,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [XDC_MAINLIST]
